import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

import RegularPage from '../styles/regularPage';
import TextSection from '../styles/textSectionStyle';

const StyledAboutUsPage = styled(RegularPage)`
	.team-section {
		> .section {
			padding-bottom: 5rem;
		}
	}
`;

export const AboutUsPageTemplate = ({ title, featuredImage }) => (
	<StyledAboutUsPage>
		<section className="section intro-section">
			<div className="container">
				<div className="columns">
					<div className="column is-3">
						<h1>{title}</h1>
					</div>
					<div className="column is-8">
						<p>
							Peaks &amp; Rafters is a full service design firm with retail
							locations in Port Carling and Toronto. We offer our clients
							quality furniture, lighting, linens and area rugs for a casual,
							comfortable, yet sophisticated lifestyle. We pride ourselves on
							the tradition of quality design and offer complete custom design
							for furniture and millwork.
						</p>
					</div>
					<div className="column is-1"></div>
				</div>
			</div>
		</section>
		<section>
			<PreviewCompatibleImage imageInfo={featuredImage} />
		</section>
		<TextSection className="section">
			<div className="container">
				<div className="columns">
					<div className="column is-3">
						<h1>Our Services</h1>
					</div>
					<div className="column is-8">
						<p>
							Our services range from full design, project management, space
							planning to decorating. We work as part of a larger team of
							client, contractor and designer to ensure that every project runs
							smoothly.
						</p>
					</div>
					<div className="column is-1"></div>
				</div>
			</div>
		</TextSection>
		{/* <section className="team-section">
			<div className="section">
				<div className="container">
					<div className="columns">
						<div className="column">
							<h2>Our Team</h2>
						</div>
					</div>
				</div>
			</div>
			<div className="columns">
				<div className="column">
					<PreviewCompatibleImage imageInfo={featuredImage} />
				</div>
			</div>
		</section> */}
	</StyledAboutUsPage>
);

AboutUsPageTemplate.propTypes = {
	title: PropTypes.string,
};

const AboutUsPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout background="light">
			<SEO title={frontmatter.title} />
			<AboutUsPageTemplate
				title={frontmatter.title}
				featuredImage={frontmatter.featured_image}
			/>
		</Layout>
	);
};

AboutUsPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default AboutUsPage;

export const aboutUsPageQuery = graphql`
	query AboutUsPage($id: String) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				featured_image {
					alt
					image {
						childImageSharp {
							fluid(maxWidth: 1366) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
`;
